import * as React from 'react';
import {GetServerSideProps} from 'next';
import {ArticlesTemplate} from '@mindfulness/cms';
import {
  Layout,
  Articles,
  ArticleBodyData,
  ReferencedArticles,
} from 'shared';
import {useLiveQuery} from 'next-sanity/preview';

import {GlobalProps, NavData} from 'types/types';
import {sanityFetch, token} from 'lib/sanity.fetch';
import {articlesHubQuery as query} from '../../groq/queries';
import {client} from 'lib/sanity.client';

const defaultParams = {
  type: 'articlesTemplate',
  from: 0,
  to: 24,
};

const PageContainer: React.FC<Props> = ({
  slug,
  draftMode,
  page,
  ...initialData
}) => {
  const params = {
    ...defaultParams,
    from: ((defaultParams.from + 1) * page) - 1,
    to: defaultParams.to * page,
  };
  const [data] = useLiveQuery(initialData, query, params);

  return (
    <>
      {data ? (
        <Layout
          client={client as any}
          page={null}
          article={null}
          draftMode={draftMode}
          navigation={data.navigation}
          sideNavigation={data.sideNavigation}
          footerNavigation={data.footerNavigation}
          site={data.site}
          data={{
            title: 'Articles',
            seoMeta: {
              title: 'Read the latest articles from the Mindfulness.com blog',
              description:
                'Learn how to live more mindfully, improve sleep, deal with anxiety, and much more.',
            },
            slug: {
              current: `/articles`,
            },
          }}
        >
          <Articles
            template={data.template}
            articles={data.articles}
            articleCount={data.articleCount}
          />
        </Layout>
      ) : null}
    </>
  );
};

/**
 * Query sanity for all the page data we need and provide it to the PageContainer as props
 */
export const getServerSideProps = (async ({
  query: urlQuery,
}) => {
  if (process.env.MINDFULNESS_SPACE !== 'mindfulness.com') {
    return {
      notFound: true,
    };
  }
  const draftMode = false;
  const slug = `/articles`;
  const page = Number(urlQuery.page || '1');
  const params = {
    ...defaultParams,
    from: ((defaultParams.from + 1) * page) - 1,
    to: defaultParams.to * page,
  };
  const data = await sanityFetch<
    {
      template: ArticlesTemplate;
      articles: Array<ReferencedArticles & ArticleBodyData>;
      articleCount: number;
      site: GlobalProps['site'];
    } & NavData
  >({
    query,
    draftMode,
    params,
  });

  return {
    props: {
      draftMode,
      token: draftMode ? token : '',
      slug,
      page,
      ...data,
    },
  };
}) satisfies GetServerSideProps<Props>;

interface Props extends GlobalProps {
  template: ArticlesTemplate;
  articles: Array<ReferencedArticles & ArticleBodyData>;
  articleCount: number;
  page: number,
}

export default PageContainer;
